export const RESET_MESSAGES = 'RESET_MESSAGES';
export const RESET_STATE = 'RESET_STATE';
export const CLEAR_SENT_MESSAGES = 'CLEAR_SENT_MESSAGES';
export const GET_PREV_MESSAGES_START = 'GET_PREV_MESSAGES_START';
export const GET_PREV_MESSAGES_SUCESS = 'GET_PREV_MESSAGES_SUCESS';
export const GET_PREV_MESSAGES_FAILURE = 'GET_PREV_MESSAGES_FAILURE';
export const GET_NEXT_MESSAGES_SUCESS = 'GET_NEXT_MESSAGES_SUCESS';
export const GET_NEXT_MESSAGES_FAILURE = 'GET_NEXT_MESSAGES_FAILURE';

export const SEND_MESSAGEGE_START = 'SEND_MESSAGEGE_START';
export const SEND_MESSAGEGE_SUCESS = 'SEND_MESSAGEGE_SUCESS';
export const SEND_MESSAGEGE_FAILURE = 'SEND_MESSAGEGE_FAILURE';

export const RESEND_MESSAGEGE_START = 'RESEND_MESSAGEGE_START';

export const ON_MESSAGE_RECEIVED = 'ON_MESSAGE_RECEIVED';
export const UPDATE_UNREAD_COUNT = 'UPDATE_UNREAD_COUNT';
export const ON_MESSAGE_UPDATED = 'ON_MESSAGE_UPDATED';
export const ON_MESSAGE_THREAD_INFO_UPDATED = 'ON_MESSAGE_THREAD_INFO_UPDATED';
export const ON_MESSAGE_DELETED = 'ON_MESSAGE_DELETED';
export const ON_MESSAGE_DELETED_BY_REQ_ID = 'ON_MESSAGE_DELETED_BY_REQ_ID';

export const SET_CURRENT_CHANNEL = 'SET_CURRENT_CHANNEL';
export const SET_CHANNEL_INVALID = 'SET_CHANNEL_INVALID';
export const MARK_AS_READ = 'MARK_AS_READ';

export const ON_REACTION_UPDATED = 'ON_REACTION_UPDATED';
export const SET_EMOJI_CONTAINER = 'SET_EMOJI_CONTAINER';

export const MESSAGE_LIST_PARAMS_CHANGED = 'MESSAGE_LIST_PARAMS_CHANGED';
