export const Type = {
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
  DANGER: 'DANGER',
  DISABLED: 'DISABLED',
};

export const Size = {
  BIG: 'BIG',
  SMALL: 'SMALL',
};
